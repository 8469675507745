
.input {
   width: 100%;
   cursor: pointer;
   margin-bottom: 2px;
   border-radius: 5px;
   min-height: 20px;
 }
.input:hover{
  border-width: 2px;
}

.button{
   min-width: 15rem;
   cursor: pointer;
   margin: 10px 0px;
 }
 .button:hover{
   opacity: 0.9;
 }

 .sidebar-card{
    margin: 20px 0px; min-height: 70px;
    padding: 20px 15px; border-radius: 1px;
 }
 .sidebar-card:hover{
    width: 100%;
    cursor: pointer;
    transform-origin: center; transition-duration: 400ms; transition-timing-function: ease-out;
 }
 .sc:hover{border-radius: 30px; border: 1px solid #00a758;}

 .fade-out{
   opacity: 0 !important;
   transition-duration: 500ms; transition-timing-function: ease-in-out;
 }
 .fade-in{
   opacity: 1 !important;
   transition-delay: 500ms; transition-duration: 500ms; transition-timing-function: ease-out;
 }

.app-header{
  position: fixed; top: 0px; width:100%; padding: 0.5em 20px; display: flex; justify-content: space-between; align-items: center; border-bottom: 1px solid gray; z-index: 100;
}
.bottom-nav{
  position: fixed; bottom: 0px; width: 100%; height: 50px; display: flex; flex-direction: row;
}
.top-nav{
  width: 100%; height: 50px; border-radius: 1px; display: flex; flex-grow: 1; overflow: hidden; flex-direction: row;
  align-items: center; justify-content: space-between;
}
.nav-menu-item {
  flex-grow: 1; text-align: center; align-items: center; justify-content: center; cursor: pointer; text-transform: capitalize;
  height: 100%; display: flex;
}
.power-box{
  width:40px; height:40px; border-radius:50%; border:1px solid #f4fde8; background-color:#fff; display:flex;  cursor:pointer;  align-items:center; justify-content:center; margin: 0px 6px;
}
.content-body-bag{ padding: 3em 1.5em 1em 1.5em; margin-top: 60px; }
.inner-content-bag { margin-top: 3.5em; margin-bottom: 3em; display: flex; width: 100%; flex-direction: column; align-items: flex-start;}
.img-regular {background-size: cover; background-position: center; background-repeat: no-repeat;}
.p-timeline-event-content {padding-right: 0px;}
.sub-menu-item-active{
  color: #fff;
  background-color: #00a758;
}
.sub-menu-item:hover, .top-nav-item:hover, .primary-color:hover {
  color: #00a758 !important;
}
.p-datatable-tbody > tr.p-row-odd { background-color: #eff5f7; }
.cart-call { bottom: 25px;}