@font-face {
  font-family: Raleway-Bold;
  src: url('./fonts/Raleway-Bold.ttf');
}
@font-face {
  font-family: Raleway-ExtraBold;
  src: url('./fonts/Raleway-ExtraBold.ttf');
}
@font-face {
  font-family: Raleway-ExtraLight;
  src: url('./fonts/Raleway-ExtraLight.ttf');
}
@font-face {
  font-family: Raleway-Light;
  src: url('./fonts/Raleway-Light.ttf');
}

@font-face {
  font-family: Raleway-Medium;
  src: url('./fonts/Raleway-Medium.ttf');
}
@font-face {
  font-family: Raleway-Regular;
  src: url('./fonts/Raleway-Regular.ttf');
}
@font-face {
  font-family: Raleway-Thin;
  src: url('./fonts/Raleway-Thin.ttf');
}



html,
body {
  padding: 0;
  margin: 0;
  font-family:Raleway-Medium;
  color: #32425b;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

*:focus{
  outline: none;
 } 
 
 ::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track{
  background-color: #abc0d4
}
::-webkit-scrollbar-thumb{
  background: #00a758;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover{
  background: #00a758;
}

.show-loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid #fff;
  border-radius: 50%;
  border-top-color: #00a758;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}
#loading {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255,255,255,.2);
  border-radius: 50%;
  border-top-color: #fff;
  margin-right: 5px;
  animation: spin 0.5s ease-in-out infinite;
  -webkit-animation: spin 0.8s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}





img {object-fit: cover;}

.add-shadow{
  box-shadow:  0 1.25rem 3.375rem rgba(90,97,105,.1);
}
.add-box-shadow{
  box-shadow:  0 .25rem .25rem rgba(90,97,105,.1);
}
.add-box-shadow2{
  box-shadow:  0 .1rem .1rem rgba(90,97,105,.1);
}
.flex-center { display: flex; align-items: center; justify-content: center;}
.show-on-mobile{ display: none !important;}

.no-padding{
  padding:0px !important;
}

.no-margin{
  margin: 0px !important;
}

.bold{
  font-family: Raleway-Bold;
}
.medium{
  font-family: Raleway-Medium;
}
.regular{
  font-family: Raleway-Regular;
}
.light{
  font-family: Raleway-Light;
}

.h1, h1 { font-size: 25px;}
.h2, h2 { font-size: 22px;}
.h3, h3 { font-size: 20px;}
.h4, h4 { font-size: 18px;}
.h5, h5 { font-size: 16px;}
.h6, h6 { font-size: 14px;}
.h7 { font-size: 12px;}


